<template>
  <v-container
    class="overflow-y-auto retro-column"
  >
    <h2
      :class="`post post-${type}`"
    >
      {{ title }}
    </h2>

    <post-input
      :column-type="type"
    />
    <div class="posts-card">
      <posts
        v-for="post in posts"
        :key="post.id"
        :post="post"
        :type="type"
        shadow-color="shadow-green"
        :text-color="`post-${type}`"
        @reloadPosts="reloadPosts"
      />
    </div>
  </v-container>
</template>

<script>

export default {
  name: 'RetroColumn',

  components: {
    PostInput: () => import('@/components/globals/PostInput.vue'),
    Posts: () => import('@/components/globals/Posts.vue'),
  },

  props: {
    title: {
      type: String,
      default: '',
    },
    posts: {
      type: Array,
      default: () => [],
      required: true,
    },
    type: {
      type: String,
      default: '',
    },
  },

  computed: {
    placeholder() {
      return this.$t('views.retro.what_was_good_about_the_week');
    },
  },

  methods: {
    reloadPosts() {
      this.$emit('reloadPosts');
    },
  },

};
</script>

<style lang="scss" scoped>
  .retro-column {

    .post {
      font-size: 24px;
      letter-spacing: 0.18px;

      &.post-good {
        color: $post-good;
      }

      &.post-improve {
        color: $post-improve;
      }

      &.post-todo {
        color: $post-todo;
      }

      &.post-notice {
        color: $post-notice;
      }
    }

    .posts-card {
      margin: 4px;
    }
  }
</style>
