<template>
  <div class="post-page">
    <v-row
      no-gutters
      justify="center"
    >
      <v-col
        class="post-collumn"
      >
        <retro-column
          :title="$t('views.retro.todos')"
          :posts="getTodosPosts"
          :user="currentUser"
          type="todo"
          @reloadPosts="reloadPosts"
        />
      </v-col>
      <v-col
        class="post-collumn"
      >
        <retro-column
          :title="$t('views.retro.good')"
          :posts="getGoodPosts"
          :user="currentUser"
          type="good"
          @reloadPosts="reloadPosts"
        />
      </v-col>
      <v-col
        class="post-collumn"
      >
        <retro-column
          :title="$t('views.retro.improve')"
          :posts="getImprovePosts"
          :user="currentUser"
          type="improve"
          @reloadPosts="reloadPosts"
        />
      </v-col>
      <v-col
        class="post-collumn"
      >
        <retro-column
          :title="$t('views.retro.notice')"
          :posts="getNoticePosts"
          :user="currentUser"
          type="notice"
          @reloadPosts="reloadPosts"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import RetroColumn from '../components/retro/RetroColumn.vue';

export default {
  name: 'Retro',

  components: {
    RetroColumn,
  },

  computed: {
    ...mapGetters([
      'getGoodPosts',
      'getImprovePosts',
      'getTodosPosts',
      'getNoticePosts',
      'currentUser',
    ]),
  },

  mounted() {
    this.fetchGoodPosts();
    this.fetchImprovePosts();
    this.fetchTodosPosts();
    this.fetchNoticePosts();
  },

  methods: {
    ...mapActions([
      'fetchGoodPosts',
      'fetchImprovePosts',
      'fetchTodosPosts',
      'fetchNoticePosts',
    ]),

    reloadPosts() {
      this.fetchGoodPosts();
      this.fetchImprovePosts();
      this.fetchTodosPosts();
      this.fetchNoticePosts();
    },
  },
};
</script>

<style lang="scss" scoped>
  .post-page {

    .post-collumn {
      background-color: $card-background;
      border-radius: 10px;
      width: 300px;
      font-family: MontserratBold;
      margin: 24px 12px;

      .post {
        font-size: 24px;
        letter-spacing: 0.18px;
        margin-top: 32px;
      }
    }
  }
</style>
